
import Vue, { PropType } from 'vue';
import InvoicesTable from '@/modules/invoice/views/InvoicesTable.vue';
// @ts-ignore convert employer model to ts
import Employer from '@/application/models/Employer.js';
import eventBus from '@/application/eventBus';

export default Vue.extend({
  name: 'Invoices',
  components: {
    InvoicesTable,
  },
  props: {
    employer: {
      type: Object as PropType<Employer>,
      default: new Employer(),
    },
  },
  created(){
    if (!this.employer) {
      this.$router.push({ name: '404' });
    }
  },
  mounted() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.invoices' },
        text: this.$tc('invoice.title', 2),
      },
    ]);
  },
});
