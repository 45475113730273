<template functional>
  <i18n-n
    :value="props.value"
    format="currency"
    class="d-flex justify-space-between"
  >
    <template #currency="slotProps">
      <span>{{ slotProps.currency }}</span>
    </template>
  </i18n-n>
</template>

<script>
export default {
  name: 'AccountingFormat',
  props: {
    value: {
      type: Number,
    },
  },
};
</script>
