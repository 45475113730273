
import Vue, { PropType } from 'vue';
import { invoiceStatus } from '@/application/enums/invoiceStatus';
import { invoiceType } from '@/application/enums/invoiceType';
import KCheckboxGroup from '@/components/crud/fields/KCheckboxGroup.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import CandidateAutocomplete from '@/modules/candidate/components/CandidateAutocomplete.vue';
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import { cleanUpFilters } from '@/application/helpers/cleanUpFilters';

interface ComponentData {
  form: {
    invoiceStatusIds: number[],
    employerId?: number,
    candidateIds: number[],
    invoiceTypeIds: number[],
    invoiceDateFrom?: string,
    invoiceDateTo?: string
  };
}

export default Vue.extend({
  name: 'InvoicesFilter',
  components: {
    KAutocomplete,
    CandidateAutocomplete,
    KSidebar,
    KFieldGroup,
    KCheckboxGroup,
    KDateField,
    EmployerAutocomplete,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object as PropType<Record<string, unknown>>,
    },
    fixedEmployerId: {
      type: Number,
      required: false,
    },
    fixedCandidateId: {
      type: Number,
      required: false,
    },
  },
  data: (): ComponentData => ({
    form: {
      candidateIds: [],
      invoiceStatusIds: [],
      invoiceTypeIds: [],
    },
  }),
  computed: {
    selectableInvoiceStatuses() {
      return getTranslatedEnumOptions(invoiceStatus, 'invoice.invoiceStatusId').filter(status =>
        [
          invoiceStatus.CREATED,
          invoiceStatus.SEND,
          invoiceStatus.PAID,
          invoiceStatus.EXPIRED,
        ].includes(status.value),
      );
    },
    invoiceTypes() {
      return getTranslatedEnumOptions(invoiceType, 'invoice.invoiceType');
    },
  },
  watch: {
    filters: {
      handler(values): void {
        if (values.candidateIds && !Array.isArray(values.candidateIds)) {
          values.candidateIds = [values.candidateIds];
        }
        if (values.invoiceStatusIds && !Array.isArray(values.invoiceStatusIds)) {
          values.invoiceStatusIds = [values.invoiceStatusIds];
        }
        if (values.invoiceTypeIds && !Array.isArray(values.invoiceTypeIds)) {
          values.invoiceTypeIds = [values.invoiceTypeIds];
        }
        this.form = { ...this.form, ...values };

        this.prefillFixedData();
      },
      deep: true,
      immediate: true,
    },
  },
  created(): void {
    this.prefillFixedData();
  },
  methods: {
    prefillFixedData(): void {
      if (this.fixedEmployerId) {
        this.form.employerId = this.fixedEmployerId;
      }

      if (this.fixedCandidateId) {
        this.form.candidateIds = [this.fixedCandidateId];
      }
    },
    handleFilterClick(): void {
      if (this.form) {
        cleanUpFilters(this.form);
      }
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    handleCleanFilter(): void {
      if (this.form) {
        this.form = {
          candidateIds: [],
          invoiceStatusIds: [],
          invoiceTypeIds: [],
        };
      }
      this.$emit('update:filters', this.form);
    },
  },
});
