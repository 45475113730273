
import FieldMixin from '@/components/crud/fields/FieldMixin.vue';
import Vue from 'vue';

interface ComponentData {
  numberValue: string | null;
}

export default Vue.extend({
  name: 'KFigureField',
  mixins: [FieldMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
    },
    step: {
      type: String,
      default: '.1',
    },
  },
  data(): ComponentData {
    return {
      numberValue: null,
    };
  },
  watch: {
    numberValue(value) {
      this.$emit('input', (!value && value !== 0) ? value : this.floatDecimals(value));
    },
  },
  created(): void {
    this.numberValue = this.value?.toString();
  },
  methods: {
    floatDecimals(numberInput: string): string {
      let decimals = 0;
      if ((Number.parseFloat(this.step) % 1) !== 0) decimals = this.step.toString().split('.')[1].length;
      return Number.parseFloat(numberInput).toFixed(decimals);
    },
  },
});
