// @ts-ignore @todo, convert api implementation into typescript
import { get } from '@/application/api/implementations/app';
import { AxiosPromise, AxiosResponse } from 'axios';
import type { TradeName } from '@/modules/companyEntity/types';

export const intermediaryTradeNames = async (): Promise<AxiosResponse<AxiosResponse<TradeName[]>>> => {
  const response = await get<AxiosResponse<TradeName[]>>('trade-name/autocomplete');

  response.data.data = response.data.data.map((tradeName) => ({
    ...tradeName,
    temporaryWorkerCostPriceFactor: (tradeName.temporaryWorkerCostPriceFactor ?? 10000) / 10000,
  }));

  return response;
};

// get all tradeNames for intermediary, filtered by contractTemplateId
export const contractTradeNames = (contractTemplateId: number): AxiosPromise => get(`contract-template/${contractTemplateId}/trade-name`);

// get all tradeNames for intermediary, filtered by priceAgreementId
export const priceAgreementTradeNames = (priceAgreementId: number): AxiosPromise => get(`price-agreement/${priceAgreementId}/detail/trade-name`);
