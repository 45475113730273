import Model from '@/application/models/ts/model';

class ManualInvoiceLine extends Model {
  title = '';
  key = Math.random().toString();
  description = '';
  amountOfUnits = 0 as number;
  unitPrice = 0 as number;
  vatPercentage = '21' as '21' | '9' | '0' | 'NO_VAT';
  total = 0 as number;

  mapResponse(data: Record<string, any>): Record<string, any> {
    return super.mapResponse({
      ...data,
      key: Math.random().toString(),
      amountOfUnits: data.amountOfUnits,
      unitPrice: data.unitPrice / 100,
      vatPercentage: data.vatPercentage === null ? 'NO_VAT' : (data.vatPercentage / 100).toString(),
      total: 0,
    });
  }

  mapForRequest(): Record<string, any> {
    return {
      ...this,
      amountOfUnits: this.amountOfUnits,
      unitPrice: Math.round(this.unitPrice * 100),
      vatPercentage: this.vatPercentage === 'NO_VAT' ? null : Math.round(Number(this.vatPercentage) * 100),
    };
  }
}

export default ManualInvoiceLine;
