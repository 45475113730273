import Model from '@/application/models/ts/model';
import ManualInvoiceLine from '@/application/models/ManualInvoiceLine';
import { invoiceTargetType } from '@/application/enums/invoiceTargetType';

export interface Recipient {
  costCenter: string | null;
  id: number
  name: string;
}

interface TradeName {
  entityId: number
  entityName: string
  id: number
  name: string
}

interface OriginalInvoice {
  id: number
  invoiceNumber: string
}

class ManualInvoice extends Model {
  invoiceId = 0;
  tradeName: TradeName | null = null;
  candidate: Recipient | null = null;
  employer: Recipient | null = null;
  location: Recipient | null = null;
  department: Recipient | null = null;
  originalInvoice: OriginalInvoice | null = null;
  originalInvoiceId: number | null = null;
  invoiceTargetType: typeof invoiceTargetType[keyof typeof invoiceTargetType] | null = null;
  invoiceLines = [new ManualInvoiceLine()];
  showCostCenter = false;
  invoiceSubTypeId = null;

  mapForRequest(): Record<string, any> {
    return {
      invoiceId: this.invoiceId,
      invoiceSubTypeId: this.invoiceSubTypeId,
      // @ts-ignore @todo, convert model.ts to support typescript constructer
      tradeNameId: this.tradeName?.id,
      // @ts-ignore @todo, convert model.ts to support typescript constructer
      candidateId: this.invoiceTargetType === 2 ? this.candidate?.id : undefined,
      // @ts-ignore @todo, convert model.ts to support typescript constructer
      departmentId: this.invoiceTargetType === 1 ? this.department?.id : undefined,
      // @ts-ignore @todo, convert model.ts to support typescript constructer
      locationId: this.invoiceTargetType === 1 ? this.location?.id : undefined,
      originalInvoiceId: this.originalInvoiceId ? this.originalInvoiceId : undefined,
      showCostCenter: this.showCostCenter,
      invoiceLines: this.invoiceLines.map((invoiceLine) => invoiceLine.mapForRequest()),
    };
  }

  mapResponse(data: ManualInvoice) {
    return super.mapResponse({
      ...data,
      invoiceId: data.invoiceId,
      // @ts-ignore @todo, convert model.ts to support typescript constructer
      invoiceTargetType: data.department?.id || data.location?.id ? 1 : 2,
      invoiceLines: data.invoiceLines.map((invoiceLine) => new ManualInvoiceLine().mapResponse(invoiceLine)),
      originalInvoiceId: data.originalInvoice,
    });
  }
}

export default ManualInvoice;
