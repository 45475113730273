
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import Vue, { PropType } from 'vue';

interface TradeName {
  id: number,
  name: string,
  entityId: number,
  entityName: string
}

interface GroupedDataItem {
  id?: number,
  name?: string,
  entityId?: number,
  entityName?: string,
  header?: string,
  divider?: boolean
}

interface componentData {
  groupedTradeNames: GroupedDataItem[]
}

export default Vue.extend({
  name: 'TradeNameAutocomplete',
  components: { KAutocomplete },
  props: {
    tradeNames: {
      type: Array as PropType<TradeName[]>,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // select item by default if only one is available
    selectIfOne: {
      type: Boolean,
      default: false,
    },
  },
  data: (): componentData => ({
    groupedTradeNames: [],
  }),
  watch: {
    tradeNames: {
      immediate: true,
      handler(newValue: TradeName[]): void {
        this.convertToGroupedTradeNames(newValue);
      },
    },
  },
  methods: {
    convertToGroupedTradeNames(originalTradeNames: TradeName[]) {
      if (originalTradeNames.length === 0) {
        return;
      }
      const tradeNames = [...originalTradeNames];
      const groupedTradeNames: GroupedDataItem[] = [];
      // make sure tradeNames are sorted on entity
      tradeNames.sort((a, b) => (a.entityId > b.entityId) ? 1 : ((b.entityId > a.entityId) ? -1 : 0));
      // used to keep track of entity while grouping the tradeNames
      let currentEntityId = tradeNames[0]?.entityId;
      //first group header
      groupedTradeNames.push({ header: tradeNames[0]?.entityName });
      // go through all tradeNames and group on entity
      tradeNames.forEach((tradeName, index) => {
        if (tradeName.entityId === currentEntityId) {
          groupedTradeNames.push(tradeName);
          return;
        }
        // create next group
        groupedTradeNames.push({ divider: true });
        groupedTradeNames.push({ header: tradeNames[index].entityName });
        groupedTradeNames.push(tradeName);
        currentEntityId = tradeNames[index].entityId;
      });
      this.groupedTradeNames = groupedTradeNames;
      if (this.selectIfOne && originalTradeNames.length === 1 && !this.$attrs.value) {
        const firstItem = this.groupedTradeNames.find(gt => gt.id === originalTradeNames[0].id);
        this.$emit('input', firstItem);
      }
    },
  },
});
