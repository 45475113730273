
import KFormDialog from '@/components/crud/KFormDialog.vue';
import TradeNameAutocomplete from '@/modules/companyEntity/components/TradeNameAutocomplete.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import EmployerAutocomplete from '@/modules/employer/components/EmployerAutocomplete.vue';
import CandidateAutocomplete from '@/modules/candidate/components/CandidateAutocomplete.vue';
import InvoiceReferencesAutocomplete from '@/modules/invoice/components/manualInvoice/InvoiceReferencesAutocomplete.vue';
import { intermediaryTradeNames } from '@/modules/companyEntity/api/tradeNameAutocomplete';
import Vue, { PropType, VueConstructor } from 'vue';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { invoiceTargetType } from '@/application/enums/invoiceTargetType';
// @ts-ignore @todo, implement typescript in index.js
import { calculateTotals, candidateAutocomplete } from '@/modules/invoice/api';
import ManualInvoiceLine from '@/application/models/ManualInvoiceLine';
import ManualInvoiceRow from '@/modules/invoice/components/manualInvoice/ManualInvoiceRow.vue';
// @ts-ignore @todo, implement typescript in enum.js
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import ManualInvoice from '@/application/models/ManualInvoice';
import InvoiceSubTypeAutocompleteVue from '@/modules/invoice/components/manualInvoice/InvoiceSubTypeAutocomplete.vue';
import LocationAutocomplete from '@/modules/location/components/LocationAutocomplete.vue';
import DepartmentAutocomplete from '@/modules/department/components/DepartmentAutocomplete.vue';
import AccountingFormat from '@/modules/invoice/views/AccountingFormat.vue';
import { invoiceSubType } from '@/application/enums/invoiceSubType';

interface TradeName {
  id: number,
  name: string,
  entityId: number,
  entityName: string
}

interface VatTotal {
  percentage: number,
  amount: number
}

interface Totals {
  subTotal: number,
  vatTotals: VatTotal[],
  total: number
}

interface InvoiceTargetType {
  value: number;
  text: string;
}

interface ComponentData {
  invoiceTargetType: typeof invoiceTargetType,
  tradeNames: TradeName[],
  showInvoiceLines: boolean,
  newInvoiceLine: ManualInvoiceLine,
  totals: Totals,
}

export default (Vue as VueConstructor<Vue & {
  $refs: {
    invoiceReferencesAutocomplete: InstanceType<typeof KPaginatedAutocomplete>
  },
}>).extend({
  name: 'ManualInvoiceForm',
  components: {
    DepartmentAutocomplete,
    LocationAutocomplete,
    InvoiceSubTypeAutocompleteVue,
    InvoiceReferencesAutocomplete,
    KFormDialog,
    TradeNameAutocomplete,
    KRadioGroup,
    KFieldGroup,
    KCheckbox,
    EmployerAutocomplete,
    CandidateAutocomplete,
    ManualInvoiceRow,
    AccountingFormat,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object as PropType<ManualInvoice>,
      default: new ManualInvoice(),
    },
    fixedEmployerId: {
      type: Number,
      required: false,
    },
    fixedCandidateId: {
      type: Number,
      required: false,
    },
  },
  data: (): ComponentData => ({
    invoiceTargetType,
    tradeNames: [],
    newInvoiceLine: new ManualInvoiceLine(),
    showInvoiceLines: false,
    totals: {
      subTotal: 0,
      vatTotals: [],
      total: 0,
    } as Totals,
  }),
  computed: {
    invoiceTargetTypes(): InvoiceTargetType[] {
      return getTranslatedEnumOptions(invoiceTargetType, 'invoice.invoiceTargetType') as InvoiceTargetType[];
    },
    activeCostCenter(): string | null | undefined {
      return this.values?.department?.costCenter || this.values?.location?.costCenter;
    },
    costCenterLabel(): string {
      return this.$t('invoice.fields.showCostCenter') + (this.activeCostCenter ? ` (${this.activeCostCenter})` : '');
    },
    invoiceTypeIsCredit() {
      return this.values.invoiceSubTypeId === invoiceSubType.CREDIT_INVOICE;
    },
    invoiceTypeIsRecruitment() {
      return this.values.invoiceSubTypeId === invoiceSubType.RECRUITMENT;
    },
    hasCandidateOrLocation() {
      return this.values.candidate || this.values.location;
    },
  },
  watch: {
    activeCostCenter(costCenter) {
      // Don't show cost center on invoice when none available for new recipient
      if (this.values.showCostCenter) {
        this.values.showCostCenter = !!costCenter;
      }
    },
    'values.invoiceSubTypeId': {
      handler(value) {
        if (value !== invoiceSubType.RECRUITMENT) return;
        this.values.invoiceTargetType = invoiceTargetType.EMPLOYER;
      },
    },
    'values.candidate': {
      handler() {
        this.showInvoiceLinesIfHidden();
      },
    },
    'values.location': {
      handler() {
        this.showInvoiceLinesIfHidden();
      },
    },
  },
  created(): void {
    this.setTradeNames();
  },
  methods: {
    candidateAutocomplete,
    async setTradeNames() {
      const response = await intermediaryTradeNames();
      this.tradeNames = response.data.data;
    },
    async calculateTotals() {
      const value = this.values.invoiceLines.map((invoiceLine: ManualInvoiceLine) => invoiceLine.mapForRequest());
      const response = await calculateTotals(value);
      this.values.invoiceLines.map((invoiceLine: ManualInvoiceLine, index: number) => invoiceLine.total = (response.data.data.lines[index].total / 100));
      this.totals.total = response.data.data.total / 100;
      this.totals.vatTotals = response.data.data.vatTotals.map((vatTotal: VatTotal) => {
        return {
          percentage: vatTotal.percentage / 100,
          amount: vatTotal.amount / 100,
        };
      });
      this.totals.subTotal = response.data.data.subTotal / 100;
    },
    showInvoiceLinesIfHidden() {
      if (this.showInvoiceLines) return;
      this.showInvoiceLines = true;
    },
    deleteInvoiceLine(index: number): void {
      this.values.invoiceLines.splice(index, 1);
      this.calculateTotals();
    },
    addNewInvoiceLine() {
      this.values.invoiceLines.push(new ManualInvoiceLine());
    },
    resetInvoiceTargetTypeData(): void {
      this.values.candidate = null;
      this.values.employer = null;
      this.resetLocation();
    },
    resetLocation(): void {
      this.values.location = null;
      this.resetDepartment();
    },
    resetDepartment(): void {
      this.values.department = null;
      this.resetOriginalInvoiceId();
    },
    resetOriginalInvoiceId(): void {
      this.values.originalInvoiceId = null;
      this.$refs.invoiceReferencesAutocomplete?.refresh();
    },
  },
});
