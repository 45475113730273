<template>
  <v-navigation-drawer
    v-bind="$attrs"
    max-width="800"
    v-on="$listeners"
  >
    <v-card
      class="pa-3"
      flat
    >
      <div v-if="withCloseButton">
        <v-btn
          absolute
          depressed
          fab
          right
          x-small
          @click="dialogClose"
        >
          <v-icon color="black">
            $close
          </v-icon>
        </v-btn>
      </div>

      <slot name="title">
        <v-card-title
          v-if="title"
          class="pa-0 mb-3"
        >
          {{ title }}
        </v-card-title>
      </slot>

      <slot name="default">
        <v-card-text>
          <slot />
        </v-card-text>
      </slot>

      <slot name="actions">
        <v-card-actions v-if="withCancelButton || withSaveButton">
          <VSpacer />
          <v-btn
            v-if="withCancelButton"
            color="primary"
            outlined
            tile
            @click="handleCancel"
          >
            {{ cancelButtonText || $t('actions.cancel') }}
          </v-btn>
          <v-btn
            v-if="withSaveButton"
            class="ml-3"
            color="primary"
            depressed
            tile
            @click="handleConfirm"
          >
            {{ saveButtonText || $t('actions.save') }}
          </v-btn>
        </v-card-actions>
      </slot>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'KSidebar',
  inheritAttrs: false,
  props: {
    withCloseButton: {
      type: Boolean,
      default: true,
    },
    withCancelButton: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: null,
    },
    withSaveButton: {
      type: Boolean,
      default: true,
    },
    saveButtonText: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    dialogClose() {
      return this.$emit('input', false);
    },
    handleConfirm() {
      this.dialogClose();
      this.$emit('click:confirm');
    },
    handleCancel() {
      this.dialogClose();
      this.$emit('click:cancel');
    },
  },
};
</script>

<style scoped>
:deep(.v-navigation-drawer__content::-webkit-scrollbar) {
  width:      8px;
  background: transparent;
}

:deep(.v-navigation-drawer__content::-webkit-scrollbar-thumb) {
  background:    #c4c4c4;
  border-radius: 5px;
}
</style>
