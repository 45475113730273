<template>
  <KCrudLayout
    :filters.sync="filters"
    :search.sync="searchQuery"
  >
    <template #header>
      {{ $t('invoice.show', { resource: $tc('invoice.title', 1) }) }}
    </template>
    <template #flow-actions>
      <v-btn
        v-if="canAddManualInvoice"
        color="primary"
        depressed
        tile
        @click="handleOpenCreateDialog"
      >
        {{ $t('actions.createResource', { resource: $tc('invoice.title', 1) }) }}
      </v-btn>
      <ManualInvoiceForm
        v-if="canAddManualInvoice"
        v-model="createDialog"
        :title="$tc('invoice.title', 1)"
        :fixed-employer-id="employerId"
        :fixed-candidate-id="candidateId"
        :request="createRequest"
        :values="createFormValues"
        @change="$refs.table.reload()"
      />
    </template>
    <template #filters="{ attrs, on }">
      <InvoicesFilter
        :fixed-employer-id="employerId"
        :fixed-candidate-id="candidateId"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <template #view.list>
      <k-crud-table
        ref="table"
        :headers="crudHeaders"
        :index-request="indexRequest"
        :params="filters"
        :search="searchQuery"
        :can-edit="invoiceIsManual"
        language-prefix="invoice.fields"
        resource="invoice"
        @click:edit="openUpdate"
        @click:row="showInvoice"
      >
        <template #item.invoiceTypeId="{ item: { invoiceTypeId } }">
          {{ $t(`invoice.invoiceType.${getEnumKeyByValue(invoiceType, invoiceTypeId)}`) }}
        </template>
        <template #item.invoiceDate="{ item: { invoiceDate } }">
          {{ invoiceDate ? dayjs(invoiceDate, 'YYYY-MM-DD').format('DD MMM YYYY') : '-' }}
        </template>
        <template #item.expiresAt="{ item: { expiresAt } }">
          <span class="text-no-wrap">{{
            expiresAt ? dayjs(expiresAt, 'YYYY-MM-DD').format('DD MMM YYYY') : '-'
          }}
          </span>
        </template>
        <template #item.expiresAtDifference="{ item: { expiresAt, invoiceTypeId, invoiceStatusId } }">
          <v-chip
            v-if="expiresAt && invoiceTypeId !== invoiceType.SELF_EMPLOYED_TO_EMPLOYER_WAGE && invoiceStatusId !== invoiceStatus.PAID"
            small
            :color="getExpiredDifferenceInDays(expiresAt) >= 0 ? 'red' :'green'"
            text-color="white"
          >
            {{ getExpiredDifferenceInDays(expiresAt) }}
          </v-chip>
        </template>
        <template #item.total="{ item: { total } }">
          {{ $n(total, 'currency') }}
        </template>
        <template #item.syncStatus="{ item: { syncStatus, id } }">
          <SyncStatusIndicator
            :sync-status="syncStatus"
            :invoice-id="id"
          />
        </template>
        <template #item.invoiceStatusId="{ item: { invoiceStatusId } }">
          {{ getTranslatedEnumValue(invoiceStatus, invoiceStatusId, 'invoice.invoiceStatusId') }}
        </template>

        <template
          #actions="{ item }"
        >
          <div class="d-inline-flex dropdown-button-container">
            <v-tooltip
              :disabled="!tooltipMessage(item)"
              top
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <dropdown-button
                    class="status-dropdown"
                    :disabled="isChangingStatus.includes(item.id) || isNotAllowedToChangeStatus(item)"
                    :class="{ 'cursor--default': isNotAllowedToChangeStatus(item) }"
                    small
                    toggle-menu-on-click
                  >
                    <span v-if="invoiceIsReminded(item)">
                      {{ $t('invoice.actions.REMINDED') }}
                    </span>
                    <span v-else>
                      {{ getTranslatedEnumValue(invoiceStatus, item.invoiceStatusId, 'invoice.invoiceStatusId') }}
                    </span>
                    <template #list>
                      <v-list>
                        <v-list-item
                          link
                          :disabled="item.invoiceStatusId === invoiceStatus.SEND"
                          @click="changeStatusTo(item.id, invoiceStatus.SEND)"
                        >
                          {{ $t('invoice.actions.SEND') }}
                        </v-list-item>

                        <v-tooltip
                          v-if="item.invoiceTypeId !== invoiceType.SELF_EMPLOYED_TO_EMPLOYER_WAGE"
                          :disabled="can('invoice.updatePaymentStatus')"
                          left
                        >
                          <template #activator="{ on }">
                            <div v-on="on">
                              <v-list-item
                                link
                                :disabled="(item.invoiceStatusId === invoiceStatus.PAID) || !can('invoice.updatePaymentStatus')"
                                @click="changeStatusTo(item.id, invoiceStatus.PAID)"
                              >
                                {{ $t('invoice.actions.PAID') }}
                              </v-list-item>
                            </div>
                          </template>
                          <span>{{ $t('invoice.messages.updateToPaymentStatusNotAllowed', { status: $t('invoice.actions.PAID') }) }}</span>
                        </v-tooltip>
                        <v-list-item
                          v-if="item.invoiceTypeId !== invoiceType.SELF_EMPLOYED_TO_EMPLOYER_WAGE"
                          link
                          :disabled="item.invoiceStatusId === invoiceStatus.EXPIRED"
                          @click="changeStatusTo(item.id, invoiceStatus.EXPIRED)"
                        >
                          {{ $t('invoice.actions.EXPIRED') }}
                        </v-list-item>
                      </v-list>
                    </template>
                  </dropdown-button>
                </div>
              </template>
              <span>{{ tooltipMessage(item) }}</span>
            </v-tooltip>
          </div>

          <v-tooltip
            :disabled="false"
            top
          >
            <template #activator="{ on }">
              <div
                class="d-inline-block"
                v-on="on"
              >
                <v-btn
                  :disabled="!item.emails.length || isEmailSending.includes(item.id)"
                  color="text--secondary"
                  icon
                  @click.stop="clickSendHandler(item)"
                >
                  <v-icon>
                    $send-email
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <div v-if="item.emails.length">
              <div>
                <strong>{{ $tc('invoice.messages.sendEmail.recipient', item.emails.length === 1 ? 1 : 2) }}:</strong>
                <span>{{ item.emails.join(', ') }}</span>
              </div>
              <div v-if="item.invoiceTypeId !== invoiceType.MANUAL_INVOICE">
                <strong>{{ $t('invoice.messages.sendEmail.timesheet') }}:</strong>
                <span>{{ timesheetHandlingText(item.timesheetAsAttachment) }}</span>
              </div>
            </div>
            <div v-else>
              {{ $t('invoice.messages.sendEmail.notAvailable') }}
            </div>
          </v-tooltip>

          <v-btn
            v-if="!item.hasTimesheet"
            :disabled="isFileDownloading.includes(item.id)"
            color="text--secondary"
            icon
            @click.stop="downloadDocument(item)"
          >
            <v-icon>$filePdf</v-icon>
          </v-btn>

          <v-menu
            v-else
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="isFileDownloading.includes(item.id)"
                color="text--secondary"
                icon
                v-bind="attrs"
                v-on="on"
                @click.stop
              >
                <v-icon>$filePdf</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="downloadDocument(item)">
                <v-list-item-content>
                  <v-list-item-title>{{ $tc('invoice.title') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="item.hasTimesheet"
                @click="downloadDocument(item, invoiceDocument.TIMESHEET)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('invoice.timesheet') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </k-crud-table>
      <ManualInvoiceForm
        v-if="canAddManualInvoice"
        ref="updateManualInvoiceForm"
        v-model="updateDialog"
        :title="$tc('invoice.title', 1)"
        :request="updateRequest"
        :values="updateFormValues"
        is-update-form
        @change="$refs.table.reload()"
      />
    </template>
  </KCrudLayout>
</template>

<script>
import { invoiceStatus } from '@/application/enums/invoiceStatus';
import { invoiceType } from '@/application/enums/invoiceType';
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import downloadFile from '@/application/util/downloadFile.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import DropdownButton from '@/components/DropdownButton.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import * as api from '@/modules/invoice/api';
import InvoicesFilter from '@/modules/invoice/components/InvoicesFilter.vue';
import dayjs from '@/plugins/dayjs.ts';
import ManualInvoiceForm from '@/modules/invoice/components/manualInvoice/ManualInvoiceForm.vue';
import ManualInvoice from '@/application/models/ManualInvoice';
import {
  createManualInvoice,
  show,
  updateManualInvoice,
  document as downloadInvoice,
  downloadTimesheet,
} from '@/modules/invoice/api';
import { mapGetters } from 'vuex';
import { invoiceTargetType } from '@/application/enums/invoiceTargetType.ts';
import { getEnumKeyByValue, getTranslatedEnumValue } from '@/application/helpers/enum.js';
import { invoiceDocument } from '@/application/enums/invoiceDocument';
import SyncStatusIndicator from '@/modules/invoice/components/SyncStatusIndicator.vue';

export default {
  name: 'InvoiceTable',
  components: {
    ManualInvoiceForm,
    InvoicesFilter,
    DropdownButton,
    KCrudTable,
    KCrudLayout,
    SyncStatusIndicator,
  },
  mixins: [querySyncMixin],
  props: {
    candidate: {
      type: Object,
    },
    employer: {
      type: Object,
    },
  },
  data() {
    return {
      filters: {},
      sidebarFilterOpen: false,
      searchQuery: '',
      isChangingStatus: [],
      isFileDownloading: [],
      isEmailSending: [],
      updateDialog: false,
      createDialog: false,
      updateFormValues: new ManualInvoice(),
      createFormValues: new ManualInvoice(),
      invoiceStatus: invoiceStatus,
      invoiceUrl: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'authorisation/profile',
      can: 'authorisation/can',
    }),
    invoiceType: () => invoiceType,
    invoiceDocument: () => invoiceDocument,
    crudHeaders() {
      return [{
        class: 'text-no-wrap',
        value: 'invoiceNumber',
        language: 'invoice.crudHeaders.invoiceNumber',
      }, {
        class: 'text-no-wrap',
        value: 'sender',
        language: 'invoice.crudHeaders.sender',
      }, {
        class: 'text-no-wrap',
        value: 'receiver',
        language: 'invoice.crudHeaders.receiver',
      }, {
        class: 'text-no-wrap',
        value: 'invoiceTypeId',
        language: 'invoice.crudHeaders.invoiceType',
      }, {
        align: 'end',
        class: 'text-no-wrap paul',
        value: 'invoiceDate',
        language: 'invoice.crudHeaders.invoiceDate',
      }, {
        align: 'end',
        class: 'text-no-wrap',
        value: 'expiresAt',
        language: 'invoice.crudHeaders.expiresAt',
        sortable: false,
      }, {
        align: 'center',
        class: 'text-no-wrap',
        value: 'expiresAtDifference',
        language: 'invoice.crudHeaders.expiresAtDifference',
        sortable: false,
      }, {
        align: 'end',
        class: 'text-no-wrap',
        value: 'total',
        language: 'invoice.crudHeaders.total',
      }, {
        align: 'center',
        class: 'text-no-wrap',
        value: 'syncStatus',
        language: 'invoice.crudHeaders.syncStatus',
        sortable: false,
      }];
    },
    canAddManualInvoice() {
      return this.can('invoice.store');
    },
    employerId() {
      return Number(this.$route.params.employerId) || null;
    },
    candidateId() {
      return Number(this.$route.params.candidateId) || null;
    },
    invoicePdfPreviewRouteName() {
      if (this.$route.params.candidateId) return 'candidate.invoice.pdf';
      if (this.$route.params.employerId) return 'employer.invoice.pdf';
      return 'invoice.pdf';
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'invoice.index' },
        text: this.$t('invoice.show', { resource: this.$tc('invoice.title', 1) }),
      },
    ]);
  },
  methods: {
    ...api,
    dayjs,
    getEnumKeyByValue,
    getTranslatedEnumValue,
    indexRequest(...[page, perPage, search, sortBy, descending, params]) {
      if (this.employer) {
        params.employerId = this.employer.id;
      }

      if (this.candidate) {
        params.candidateIds = [this.candidate.id];
      }

      return api.index(...[page, perPage, search, sortBy, descending, params]);
    },
    handleOpenCreateDialog() {
      this.createFormValues = new ManualInvoice();
      if (this.candidate) {
        this.createFormValues.invoiceTargetType = invoiceTargetType.CANDIDATE;
        this.createFormValues.candidate = {
          id: this.candidate.id,
          name: this.candidate.name,
        };
      }
      if (this.employer) {
        this.createFormValues.invoiceTargetType = invoiceTargetType.EMPLOYER;
        this.createFormValues.employer = {
          costCenter: this.employer.costCenter,
          id: this.employer.id,
          name: this.employer.name,
        };
      }
      this.createDialog = true;
    },
    getExpiredDifferenceInDays(expiresAt) {
      const expiresAtDateInMilliseconds = new Date(expiresAt).getTime();
      const todayInMilliseconds = new Date().getTime();
      const difference = todayInMilliseconds - expiresAtDateInMilliseconds;
      // return in days
      return Math.floor(difference / (1000 * 3600 * 24));
    },
    async changeStatusTo(invoiceId, to) {
      try {
        this.isChangingStatus.push(invoiceId);
        await this.status(invoiceId, to);
        this.$refs.table.reload();
        this.isChangingStatus = this.isChangingStatus.filter(id => id !== invoiceId);
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('invoice.messages.errors.statusChange'),
        });
      }
    },
    async downloadDocument(item, invoiceDocumentType = invoiceDocument.INVOICE) {
      try {
        this.isFileDownloading.push(item.id);

        let title;
        let document;

        if (invoiceDocumentType === invoiceDocument.INVOICE) {
          title = item.invoiceNumber;
          document = await downloadInvoice(item.id);
        } else if (invoiceDocumentType === invoiceDocument.TIMESHEET) {
          title = this.$t('invoice.timesheetTitle', { invoiceNumber: item.invoiceNumber });
          document = await downloadTimesheet(item.id);
        }

        if (!document) throw new Error;

        await downloadFile(document, title);

        this.isFileDownloading = this.isFileDownloading.filter(id => id !== item.id);

        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('actions.resourceDownloaded', { resource: title }),
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('invoice.messages.errors.download'),
        });
      }
    },
    clickSendHandler(item) {
      if (!item.isSent) {
        this.sendInvoice(item);
        return;
      }

      eventBus.$emit('confirm', {
        title: this.$t(`invoice.messages.${this.invoiceIsReminded(item) ? 'sendReminderAgainTitle' : 'sendEmailAgainTitle'}`),
        body: this.$t('invoice.messages.sendEmailAgainBody', {
          invoiceNumber: item.invoiceNumber,
          emails: new Intl.ListFormat(this.$i18n.locale).format(item.emails),
        }),
        confirmButtonText: this.$t('actions.send'),
        confirmCallback: () => this.sendInvoice(item),
      });
    },
    async sendInvoice(item) {
      try {
        this.isEmailSending.push(item.id);
        await this.send(item.id);
        this.isEmailSending = this.isEmailSending.filter(id => id !== item.id);
        this.changeStatusTo(item.id, this.SEND);
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('invoice.messages.successfullySent', { emails: this.formatEmails(item.emails) }),
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('invoice.messages.errors.sendInvoice'),
        });
      }
    },
    createRequest() {
      const invoice = this.createFormValues.mapForRequest();
      return createManualInvoice(invoice);
    },
    invoiceIsManual(invoice) {
      return invoice.invoiceTypeId === invoiceType.MANUAL_INVOICE;
    },
    updateRequest() {
      const invoice = this.updateFormValues.mapForRequest();
      return updateManualInvoice(invoice, invoice.invoiceId);
    },
    async openUpdate(item) {
      if (!this.invoiceIsManual(item)) {
        return;
      }

      this.updateFormValues = new ManualInvoice();
      const { data: { data } } = await show(item.id);
      this.updateFormValues.mapResponse(data);
      this.$refs.updateManualInvoiceForm.calculateTotals();
      this.updateDialog = true;
    },
    showInvoice(item) {
      this.$router.push({ name: this.invoicePdfPreviewRouteName, params: { invoiceId: item.id } });
    },
    formatEmails(emails) {
      return new Intl.ListFormat(this.profile.language).format(emails);
    },
    timesheetHandlingText(timesheetAsAttachment) {
      if (timesheetAsAttachment) {
        return this.$t('invoice.messages.sendEmail.asAttachment');
      }
      if (timesheetAsAttachment === false) {
        return this.$t('invoice.messages.sendEmail.appendToInvoice');
      }
      return this.$t('invoice.messages.sendEmail.none');
    },
    isNotAllowedToChangeStatus(item) {
      return item.invoiceStatusId === invoiceStatus.PAID && !this.can('invoice.updatePaymentStatus');
    },
    invoiceIsReminded(item) {
      if (!item.sentAt || ![invoiceStatus.SEND, invoiceStatus.EXPIRED].includes(item.invoiceStatusId)) return false;
      return dayjs(item.sentAt).isAfter(dayjs(item.expiresAt));
    },
    tooltipMessage(item) {
      if (this.isNotAllowedToChangeStatus(item)) {
        return this.$t('invoice.messages.updateWithPaymentStatusNotAllowed', { status: this.$t('invoice.actions.PAID') });
      }

      if (item.sentAt && [invoiceStatus.SEND, invoiceStatus.EXPIRED].includes(item.invoiceStatusId)) {
        return this.$t(`invoice.messages.${this.invoiceIsReminded(item) ? 'remindedAt' : 'sentAt' }`, { date: dayjs(item.sentAt).format('LLL') });
      }
    },
  },
};
</script>

<style scoped>
.dropdown-button-container {
  vertical-align: middle;
}

:deep(.status-dropdown) button:first-child {
  width: 125px;
}

</style>
