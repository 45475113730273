<template>
  <KPaginatedAutocomplete
    ref="autocomplete"
    :paginator="autocomplete"
    item-text="invoiceNumber"
    item-value="id"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { invoiceReferences } from '@/modules/invoice/api';

export default {
  name: 'InvoiceReferencesAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    tradeNameId: {
      type: Number,
      required: true,
    },
    candidateId: {
      type: Number,
      required: false,
    },
    employerId: {
      type: Number,
      required: false,
    },
    locationId: {
      type: Number,
      required: false,
    },
    departmentId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    refresh() {
      this.$refs.autocomplete.refresh();
    },
    autocomplete() {
      return invoiceReferences({
        tradeNameId: this.tradeNameId,
        candidateId: this.candidateId,
        employerId: this.employerId,
        locationId: this.locationId,
        departmentId: this.departmentId,
      });
    },
  },
};
</script>
