
import Vue from 'vue';
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import { invoiceSubType as invoiceSubTypes } from '@/application/enums/invoiceSubType';
// @ts-ignore todo: refactor enum.js into typescript
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';

interface InvoiceSubType {
  value: number,
  text: string
}

export default Vue.extend({
  name: 'InvoiceSubTypeAutocompleteVue',
  components: { KAutocomplete },
  computed: {
    invoiceSubTypes(): InvoiceSubType[] {
      return getTranslatedEnumOptions(invoiceSubTypes, 'invoice.invoiceSubTypes') as InvoiceSubType[];
    },
  },
});
