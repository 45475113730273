
import Field from '@/components/crud/fields/FieldMixin.vue';
import Vue from 'vue';

interface ComponentData {
  checked: unknown[]
}

export default Vue.extend({
  name: 'KCheckboxGroup',
  mixins: [Field],
  inheritAttrs: false,
  props: {
    value: { type: [Array, Number] },
    checkboxGroupClass: { type: String },
    checkboxClass: { type: String },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: (): ComponentData => ({
    checked: [],
  }),
  watch: {
    checked(values) {
      this.$emit('input', values);
    },
    value: {
      handler(newVal){
        if (typeof newVal !== 'undefined') {
          this.checked = Array.isArray(newVal) ? newVal : [newVal];
        }
      },
      immediate: true,
    },
  },
});
